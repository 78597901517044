import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import Checkbox from "./Checkbox";
import Button from "./Button";
import ActionButton from "./ActionButton";
import TextArea from "./TextArea";
import TextEditor from "./TextEditor";
import FileInput from "./FileInput";

const Controls = {
    Input,
    RadioGroup,
    Select,
    Checkbox,
    Button,
    ActionButton,
    TextArea,
    TextEditor,
    FileInput
}

export default Controls;